import React from "react";
import "./styles.css";

export class CardDiferenciais extends React.Component {
  render() {
    return (
      <div id="diferencial">
        <div id="icon">
          <img src={this.props.Icon} alt="" />
        </div>
        <div id="text">
          <h3>{this.props.Title}</h3>
          <p>{this.props.Description}</p>
        </div>
      </div>
    );
  }
}
