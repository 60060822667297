import styles from "./styles.module.scss";
import logo from "../imgs/Logotipo.png";
import React, { useState } from "react";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false); // Fecha o menu após clicar no link
  };

  return (
    <nav>
      <div className={styles.navbar}>
        <img src={logo} alt="" />
        <div
          className={`${styles.navbarMenu} ${isOpen ? styles.isMobile : ""}`}
        >
          <a href="#home" onClick={() => scrollToSection("home")}>
            Planos
          </a>
          {/* <a href="#about" onClick={() => scrollToSection("about")}>
            About
          </a>
          <a href="#services" onClick={() => scrollToSection("services")}>
            Services
          </a>
          <a href="#contact" onClick={() => scrollToSection("contact")}>
            Contact
          </a> */}
        </div>
        <button className={styles.navbarToggle} onClick={toggleMenu}>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </button>
      </div>
    </nav>
  );
};
