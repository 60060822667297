import styles from "./styles.module.scss";
import React from "react";
import Img1 from "../imgs/principal.png";
import { BotaoVermelho } from "../BotaoVermelho/BotaoVermelho";

export class Principal extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.principalcontent}>
          <div>
            <h1>
              Sua <span>Nova Internet</span>, com a qualidade que você procura!
            </h1>
            <p>
              Serviço de qualidade, com atendimento e suporte feito por pessoas
              reais que estão pertinho de você!
            </p>
          </div>
          <img src={Img1} alt="" />
        </div>
        <div className={styles.principalbtn}>
          <BotaoVermelho Text="Fale com nosso consultor" />
        </div>
      </div>
    );
  }
}
