import styles from "./styles.module.scss";
import React from "react";

export class BotaoVermelho extends React.Component {
  render() {
    return (
      <a
        className={styles.botaoVermelho}
        href="https://api.whatsapp.com/send?phone=5518996070369&text=Ol%C3%A1,%20quero%20contratar%20a%20internet!"
      >
        {this.props.Text}
      </a>
    );
  }
}
