import styles from "./styles.module.scss";
import React from "react";

export class CardPacote extends React.Component {
  render() {
    return (
      <div className={styles.pacote}>
        <div className={styles.internet}>
          <p>Fibra</p>
          <h2>200MB</h2>
        </div>
        <div className={styles.comodidades}>
          <p className={styles.list}>Instalação gratuita.</p>
          <p className={styles.list}>Suporte sempre.</p>
          <p className={styles.list}>Internet de qualidade.</p>
        </div>
        <div className={styles.infoprice}>
          <div className={styles.apenas}>
            <p>
              Por <br></br>apenas
            </p>
          </div>
          <div className={styles.price}>
            R$<span className={styles.destaqueprice}>89</span>,90
          </div>
        </div>
        <a href="https://api.whatsapp.com/send?phone=5518996070369&text=Ol%C3%A1,%20quero%20contratar%20a%20internet!">
          Contratar
        </a>
      </div>
    );
  }
}
