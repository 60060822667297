import React from "react";
import styles from "./styles.module.scss";
import { CardPacote } from "../CardPacote/CardPacote";

export class ListaPacotes extends React.Component {
  render() {
    return (
      <div className={styles.listapacotes}>
        <CardPacote />
        <CardPacote />
        <CardPacote />
      </div>
    );
  }
}
