import "./styles.css";
import React from "react";

export class CardeIdeal extends React.Component {
  render() {
    return (
      <div id="card-ideal">
        <div id="imagem">
          <img src={this.props.Imagem} alt="" />
        </div>
        <div id="infos">
          <h3>{this.props.Title}</h3>
          <p>{this.props.Description}</p>
        </div>
      </div>
    );
  }
}
