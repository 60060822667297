import "./styles/global.css";
import { BotaoVermelho } from "./BotaoVermelho/BotaoVermelho";
import { CardDiferenciais } from "./CardDiferenciais/CardDiferenciais";
import { CardeIdeal } from "./CardIdeal/CardIdeal";
import { ListaPacotes } from "./ListaPacotes/ListaPacotes";
import { Navbar } from "./Navbar/Navbar";
import { Principal } from "./Principal/Principal";
import { Titulo } from "./Titulo/Titulo";
import icon1 from "./imgs/icon1.png";
import card1 from "./imgs/Internet.png";
import { Rodape } from "./Rodape/Rodape";

function App() {
  return (
    <>
      <Navbar />

      <div className="bodyoficial">
        <Principal />

        <section id="home">
          <Titulo
            TituloPassado="Encontre o plano certo"
            Descricao="Arraste para o lado e conheça!"
          />
          <ListaPacotes />
        </section>

        {/* <Titulo
          TituloPassado="É hora de mudar!"
          Descricao="Leia se ainda não está convencido..."
        />
        <div className="lista-diferenciais">
          <CardDiferenciais
            Icon={icon1}
            Title="Fibra"
            Description="Internet de alta velocidade com tecnologia comprovada."
          />

          <CardDiferenciais
            Icon={icon1}
            Title="Fibra"
            Description="Internet de alta velocidade com tecnologia comprovada."
          />

          <CardDiferenciais
            Icon={icon1}
            Title="Fibra"
            Description="Internet de alta velocidade com tecnologia comprovada."
          />

          <CardDiferenciais
            Icon={icon1}
            Title="Fibra"
            Description="Internet de alta velocidade com tecnologia comprovada."
          />
        </div>

        <BotaoVermelho Text="Saber mais" />

        <Titulo
          TituloPassado="É hora de mudar!"
          Descricao="Leia se ainda não está convencido..."
        />
        <CardeIdeal
          Imagem={card1}
          Title="Ideal para ver vídeos"
          Description="Assista filmes e séries em 4K sem interrupções."
        />

        <CardeIdeal
          Imagem={card1}
          Title="Ideal para ver vídeos"
          Description="Assista filmes e séries em 4K sem interrupções."
        />

        <CardeIdeal
          Imagem={card1}
          Title="Ideal para ver vídeos"
          Description="Assista filmes e séries em 4K sem interrupções."
        />

        <CardeIdeal
          Imagem={card1}
          Title="Ideal para ver vídeos"
          Description="Assista filmes e séries em 4K sem interrupções."
        />

        <BotaoVermelho Text="Contratar agora" /> */}
      </div>

      <section id="home">
        <Rodape />
      </section>
    </>
  );
}

export default App;
