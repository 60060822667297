import styles from "./styles.module.scss";
import React from "react";

export class Titulo extends React.Component {
  render() {
    return (
      <div className={styles.titulo}>
        <h1>{this.props.TituloPassado}</h1>
        <p>{this.props.Descricao}</p>
      </div>
    );
  }
}
