import styles from "./styles.module.scss";
import logo from "../imgs/Logotipo.png";
import React, { useState } from "react";

export const Rodape = () => {
  return (
    <div className={styles.container}>
      <div>
        <img src={logo} alt="" />
      </div>
      <div>
        <p>
          © 2024 Todos os direitos reservados. MEGANET PROVEDOR DE INTERNET
          LTDA. CNPJ: 43.316.862/0001-30 - Getulio Vargas, 231 - Luiziânia - SP
        </p>
      </div>
    </div>
  );
};
